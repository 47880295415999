// Import mixins
@import "mixins.scss";

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-vb-theme="dark"] {
  .ant-skeleton {
    opacity: 0.1;
  }
}
