@import "mixins.scss";

[data-vb-theme="default"] {
  .ant-form {
    legend {
      border: none;
      width: auto;
      margin: revert;
      padding-inline-start: 4px;
      padding-inline-end: 12px;
      margin-inline-start: 4px;
      font-family: "Nunito Sans", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 12px;
      display: flex;
      align-items: center;
      color: #888b8c;
    }
    // &-item {
    //   margin-bottom: 0.5rem;
    // }
    &-item {
      margin: 0;
    }
  }
}
